import sumBy from'lodash/sumBy';import{normalizeChatMessage}from'soapbox/normalizers';import{ChatKeys}from'soapbox/queries/chats';import{queryClient}from'soapbox/queries/client';import{compareDate}from'./comparators';import{appendPageItem,flattenPages,sortQueryData,updatePageItem}from'./queries';/**
 * Update the Chat entity inside the ChatSearch query.
 * @param newChat - Chat entity.
 */const updateChatInChatSearchQuery=newChat=>{updatePageItem(ChatKeys.chatSearch(),newChat,(o,n)=>o.id===n.id);};/**
 * Re-order the ChatSearch query by the last message timestamp.
 */const reOrderChatListItems=()=>{sortQueryData(ChatKeys.chatSearch(),(chatA,chatB)=>{var _chatA$last_message,_chatB$last_message;return compareDate((_chatA$last_message=chatA.last_message)===null||_chatA$last_message===void 0?void 0:_chatA$last_message.created_at,(_chatB$last_message=chatB.last_message)===null||_chatB$last_message===void 0?void 0:_chatB$last_message.created_at);});};/**
 * Check if a Chat entity exists within the cached ChatSearch query.
 * @param chatId - String
 * @returns Boolean
 */const checkIfChatExists=chatId=>{const currentChats=flattenPages(queryClient.getQueryData(ChatKeys.chatSearch()));return currentChats===null||currentChats===void 0?void 0:currentChats.find(chat=>chat.id===chatId);};/**
 * Force a re-fetch of ChatSearch.
 */const invalidateChatSearchQuery=()=>{queryClient.invalidateQueries(ChatKeys.chatSearch());};const updateChatListItem=newChat=>{const{id:chatId,last_message:lastMessage}=newChat;const isChatAlreadyLoaded=checkIfChatExists(chatId);if(isChatAlreadyLoaded){// If the chat exists in the client, let's update it.
updateChatInChatSearchQuery(newChat);// Now that we have the new chat loaded, let's re-sort to put
// the most recent on top.
reOrderChatListItems();}else{// If this is a brand-new chat, let's invalid the queries.
invalidateChatSearchQuery();}if(lastMessage){// Update the Chat Messages query data.
appendPageItem(ChatKeys.chatMessages(newChat.id),normalizeChatMessage(lastMessage));}};/** Get unread chats count. */const getUnreadChatsCount=()=>{const chats=flattenPages(queryClient.getQueryData(ChatKeys.chatSearch()));return sumBy(chats,chat=>chat.unread);};export{updateChatListItem,getUnreadChatsCount};